<script setup>
import { JRow } from "media-flow-ui";

import { toRefs } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null
  }
});

const { data } = toRefs(props);
</script>


<template>
  <JRow :class="[data.id, data.class]" :id="data.id" :key="data.id" :x-alignment="data.props?.xAlignment"
    :y-alignment="data.props?.yAlignment" :is-container="data.style.isContainer">

    <template v-if="data.children && data.children.length > 0">
      <Identifier v-for="component in data.children" :component="component" :key="component.id" :rowOrder="rowOrder" />
    </template>
    <!-- <Identifier :component="data.children" /> -->
  </JRow>
</template>
